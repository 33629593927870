<template>
  <div class="card card-custom">
    <div class="card-body p-0">
        <div class="row justify-content-center my-10 px-8 my-lg-15 px-lg-21">
          <b-tabs content-class="mt-3">
            <b-tab title="Delivery Charges" active>
              <DeliveryCharge></DeliveryCharge>
            </b-tab>
            <b-tab title="Delivery Times">
              <DeliveryCollectionTimeInfo></DeliveryCollectionTimeInfo>
            </b-tab>
          </b-tabs>
        </div>
    </div>
  </div>
</template>

<style lang="scss">
@import "@/assets/sass/pages/wizard/wizard-1.scss";
</style>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Swal from "sweetalert2";
import DeliveryCharge from "@/view/pages/business/components/DeliveryCharge";
// import DeliveryTime from "@/view/pages/business/components/DeliveryTime";
import DeliveryCollectionTimeInfo from "@/view/pages/business/components/DeliveryCollectionTimeInfo";

export default {
  name: "DeliveryInfo",
  components: {
    DeliveryCharge,
    DeliveryCollectionTimeInfo,
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Business Settings" },
      { title: "Delivery Info" }
    ]);
  },
  methods: {
    submit: function(e) {
      e.preventDefault();
      Swal.fire({
        title: "",
        text: "The application has been successfully submitted!",
        icon: "success",
        confirmButtonClass: "btn btn-secondary"
      });
    }
  }
};
</script>
